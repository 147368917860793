.giohang-cover {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0rem;
    width: 100%;
    margin-top: 2.5625rem;
    height: auto;
}

.giohang-table {
    position: relative;

    /* Frame 427322735 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0rem;
    gap: 1.0625rem;

    position: relative;
    width: 100%;
    height: auto;
}

.giohang-table-header {
    /* Frame 427322717 */

    box-sizing: border-box;
    position: relative;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem 0rem;

    width: 100%;
    height: 2.3125rem;

    background: #ffffff;
    /* Color/brand/primary */
    border-width: 0.0625rem 0rem;
    border-style: dashed;
    border-color: #05a9ad;

    /* Sản phẩm */

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    /* color/text-dark */
    color: #001535;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.giohang-check-all {
    /* Frame 427322726 */
    display: flex;
    align-items: center;
    width: 1.25rem;
    height: 100%;
}

input[type="checkbox"]:checked:after {
    content: "\2713";
    color: white;
}

input[type="checkbox"] {
    accent-color: #05a9ad;
}

.form-giohang-input {
    /* Frame 427322726 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0rem;
    gap: 0.625rem;
    /* Color/brand/primary */
    background: #05a9ad;
    width: 1rem;
    height: 1rem;
    accent-color: #05a9ad;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sanpham-header {
    /* Frame 427322715 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.375rem 0.625rem;
    gap: 0.625rem;

    /* width: 63.625rem; */
    height: 1.8125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.giohang-gia-header {
    /* Frame 427322716 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.625rem;
    gap: 0.625rem;

    width: 10rem;
    height: 1.8125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-soluong-header {
    /* Frame 427322717 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.625rem;
    gap: 0.625rem;

    width: 6.25rem;
    height: 1.8125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sotien-header {
    /* Frame 427322718 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.625rem;
    gap: 0.625rem;

    width: 10rem;
    height: 1.8125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.yeuthich-themvaogiohang-header {
    /* Frame 427322718 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.625rem;
    gap: 0.625rem;

    width: 12.5rem;
    height: 1.8125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-thaotac-header {
    /* Frame 427322719 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.625rem;
    gap: 0.625rem;

    width: 6.25rem;
    height: 1.8125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-table-row {
    /* Frame 427322725 */
    position: relative;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 0.3125rem;

    width: 100%;
    height: 5.75rem;

    border-bottom: 0.05rem dashed #d9d9d9;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.giohang-check-cell {
    /* Frame 427322726 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0rem;
    gap: 0.625rem;

    width: 1.25rem;
    height: 1.25rem;

    /* Color/brand/primary */
    background: #05a9ad;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sanpham-cell {
    /* Frame 427322720 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 0.625rem;

    /* width: 63.125rem; */
    height: 5rem;
    margin-left: 0.625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.giohang-sanpham-img {
    /* Frame 427322736 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.375rem 0rem;
    gap: 0.625rem;

    width: 5.75rem;
    height: 5rem;

    background: #ffffff;
    border-radius: 0.25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sanpham-text {
    /* Frame 427322721 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.25rem 0rem 0rem;
    gap: 0.625rem;

    /* width: 56.75rem; */
    height: 1.4375rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;

    /* ĐÈN THẢ EGLO 94938 - GAETANO 1 */

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    display: flex;
    align-items: center;

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);
}

.giohang-gia-cell {
    /* Frame 427322659 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0rem;
    gap: 0.25rem;

    width: 10rem;
    height: 1.6875rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* 519.000 */

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    display: flex;
    align-items: center;
    text-align: center;

    /* Color/brand/primary */
    color: #05a9ad;
}

.giohang-soluong-cell {
    /* Frame 427322723 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 0.625rem;

    min-width: 6.25rem;
    height: 1.8125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-tang-giam {
    /* Frame 427322663 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.625rem;
    gap: 0.625rem;

    min-width: 2rem;
    height: 1.8125rem;

    /* color/line */
    border: 0.0625rem solid #efefef;
    border-radius: 0rem 0.125rem 0.125rem 0rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.giohang-thaotac-cell {
    /* Frame 427322724 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0rem;
    gap: 0.625rem;

    width: 6.25rem;
    height: 1.5625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-xoa-btn {
    /* Frame 427322724 */

    width: 6.25rem;
    height: 1.5625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    /* Inter/medium/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* Color/white */
    color: var(--Color-white, #ffffff);
}

.giohang-summerize-cover {
    /* Frame 427322728 */
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 100%;
    height: 4.1875rem;
    margin-top: 13.3125rem;

    background: #ffffff;
    box-shadow: 0rem -0.25rem 0.625rem rgba(0, 0, 0, 0.16);
}

.giohang-summerize-row {
    /* Frame 427322734 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
    gap: 1.8125rem;

    position: relative;
    width: 100%;
    height: 2rem;

    /* Sản phẩm */

    /* Inter/Regular/.875rem */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;

    /* color/text-dark */
    color: #001535;
}

.giohang-sum-left {
    /* Frame 427322733 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 0.375rem;

    margin: 0 auto 0 0;
    /* width: 24.6875rem; */
    height: 1.8125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.form-check-label {
    /* Frame 427322715 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* Inside auto layout */
    flex: none;
}

.giohang-xoa {
    /* Frame 427322715 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.375rem 1rem;
    gap: 0.625rem;

    width: 3.5625rem;
    height: 1.8125rem;

    /* color/text-dark */
    /* border-left: .0625rem solid #001535;
 */
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sum-right {
    /* Frame 427322737 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;

    margin: 0 0 0 auto;
    width: 40.5625rem;
    height: 2rem;

    /* Inside auto layout */
    /* flex: none; */
    flex-grow: 1;
}

.giohang-uudai {
    /* Form / Text */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 1rem;

    margin: 0 auto;
    width: 18.375rem;
    height: 2rem;

    border-radius: 0.3125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-uudai-text {
    /* Nhập mã ưu đãi */

    height: 1.0625rem;
    /* Inter/Regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* Color/text/dark */
    color: #1c1c1c;
    display: flex;
    align-items: center;

    /* Color/text/dark */
    color: #1c1c1c;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-uudai-input {
    /* Frame 16290 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.375rem 0.4375rem;
    gap: 0.4375rem;

    width: 10.875rem;
    height: 2rem;

    /* BACKGROUND/B */
    background: #ffffff;
    /* color/border-input */
    border: 0.0625rem solid #ced4da;
    border-radius: 0.3125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.giohang-tienthanhtoan {
    /* Frame 427322731 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;

    min-width: 12.9375rem;
    height: 1.8125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.giohang-tongtien {
    /* Frame 427322732 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 2.25rem;

    margin: 0 auto;
    width: 20.875rem;
    height: 2rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-tien-thanhtoan {
    /* Frame 427322721 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0rem;
    gap: 0.25rem;

    min-width: 4.6875rem;
    height: 1.6875rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;

    /* Inter/Medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.1875rem;
    display: flex;
    align-items: center;
    text-align: center;

    /* Color/red */
    color: #ec0e00;
}

.giohang-thahnhtoan {
    /* Tìm kiếm */

    /* Inter/Regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* color/white */
    color: var(--Color-white, #ffffff);

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* Tìm kiếm */

    /* Frame 427322678 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.4688rem 0.5rem;
    gap: 0.25rem;

    width: 5.6875rem;
    height: 2rem;

    /* Color/brand/primary */
    background: #05a9ad;
    border-radius: 0.25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.yeuthich-btn {
    /* Tìm kiếm */

    /* Inter/Regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* color/white */
    color: var(--Color-white, #ffffff);

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* Tìm kiếm */

    /* Frame 427322678 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.4688rem 0.5rem;
    gap: 0.25rem;

    height: 2rem;

    /* Color/brand/primary */
    background: #05a9ad;
    border-radius: 0.25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.yeuthich-themvaogiohang {
    /* Tìm kiếm */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0rem;
    gap: 0.25rem;

    width: 12.5rem;
    height: 1.6875rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* 519.000 */

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    display: flex;
    align-items: center;
    text-align: center;

    /* Color/brand/primary */
    color: #05a9ad;
}
