.news-hightlight {
    /* NEWS */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0rem;
    gap: 2.75rem;

    position: relative;
    width: 100%;
    height: 40.5625rem;
    margin: 2.4375rem 0rem 0rem 0rem;
}

.news-hightlight-left {
    /* card-news */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.625rem;
    isolation: isolate;
    position: relative;
    text-decoration: none;

    max-width: 62.1875rem;
    height: 40.5625rem;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
}

.hightlight-big-img {
    /* image 13 */

    width: 100%;
    max-height: 100%;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    z-index: 0;
}

.hightlight-big-content {
    /* Frame 27 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.625rem;
    gap: 0.375rem;

    position: absolute;
    min-height: 5.125rem;
    height: auto;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;

    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(0.125rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 0.375rem 0.375rem 0rem 0rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 1;
}

.hightlight-big-title {
    /* title-news */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.25rem 0rem;
    gap: 0.625rem;

    width: 100%;
    height: auto;
    margin-bottom: auto;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    /* Sự nâng cấp cho đèn pha lê hoa mai danh tiếng */

    /* Inter/medium/1rem */
    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;
}

.hightlight-big-text {
    /* Frame 28 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.625rem;
    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    height: auto;
    margin-bottom: auto;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;
    width: 100%;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.news-hightlight-right {
    /* Frame 33 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.5625rem;
    position: relative;

    width: 32.5625rem;
    height: 40.5625rem;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.news-hightlight-right-item {
    /* card-news */
    position: relative;
    width: 32.5625rem;
    height: 19.5rem;
    text-decoration: none;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.hightlight-small-img {
    /* image 15 */

    position: absolute;
    width: 100%;
    height: 19.5rem;
    left: 0rem;
    top: 0rem;
}

.hightlight-small-content {
    /* Frame 27 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.625rem;
    gap: 0.375rem;

    position: absolute;
    min-height: 5.4375rem;
    height: auto;
    left: 0rem;
    right: 0rem;
    bottom: 0rem;

    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(0.125rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 0.375rem 0.375rem 0rem 0rem;
}

.hightlight-small-title {
    /* title-news */
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.25rem 0rem;
    gap: 0.625rem;

    width: 100%;
    height: auto;
    margin-bottom: auto;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    /* Sự nâng cấp cho đèn pha lê hoa mai danh tiếng */

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;
}

.hightlight-small-text {
    /* Frame 28 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.625rem;
    /* Bạn có biết rằng bướm là biểu tượng của sự hồi sinh, sự thay đổi và sự vui vẻ không? */

    width: 31.3125rem;
    height: auto;

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;

    width: 100%;
    height: 1.1875rem;

    /* Inside auto layout */
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.news-list {
    /* Frame 427322757 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0rem;
    gap: 2.5rem;

    position: relative;
    width: 100%;
}

.news-list-item {
    /* news-item */

    position: relative;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0rem;

    width: 29.7919rem;
    height: 27.6875rem;

    /* Color/white */
    background: #ffffff;
    border: 0.125rem solid #ffffff;
    box-shadow: 0rem 0rem 0.625rem #ebebeb;
    border-radius: 0.25rem;

    /* Inside auto layout */
    flex: none;
}

.news-image {
    /* Frame 427322750 */

    /* Frame 427322750 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.625rem;
    gap: 0.625rem;

    width: 100%;
    height: 18.5625rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.news-img {
    /* image 62 */
    /* image 62 */

    width: 100%;
    height: 17.125rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.news-content {
    /* Frame 427322758 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 0rem 1rem;
    gap: 0.375rem;

    width: 100%;
    min-height: 9.125rem;
    height: auto;
    /* Color/white */
    background: #ffffff;
    /* Color/text/placeholder */
    border-top: 0.0625rem solid #b5b5b5;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.news-title {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem;
    gap: 0.625rem;
    /* text-transform: uppercase; */
    width: 100%;
    height: auto;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 1;
    margin-bottom: auto;

    /* EGLO OUTLET – LỰA CHỌN NÂNG CẤP ĐÈN TRANG TRÍ HIỆU GIÁ HỜI, GIẢM ĐẾN 90% */

    /* Inter/medium/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.1875rem;

    color: #ffbb0b;
}

.news-text {
    /* Frame 427322754 */

    /* Frame 427322754 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 0.625rem;
    gap: 0.625rem;

    width: 100%;
    height: 4.125rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;

    /* Inter/regular/.875rem-content */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    /* or 157% */
    text-align: justify;

    /* Color/text/dark */
    color: #1c1c1c;
}

.news-detail-cover {
    /* Frame 427322766 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.875rem 1rem;
    gap: 2.3125rem;
    margin-top: 2.4375rem;
    position: relative;
    width: 100%;
    height: auto;

    background: #ffffff;
    border-radius: 0.625rem;
}

.news-detail-header {
    /* Frame 427322760 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;

    width: 100%;
    height: 1.1875rem;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    /* EGLO CONNECTS – ĐÈN THÔNG MINH CHO MỌI GIA ĐÌNH! */

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    /* Color/text/dark */
    color: #1c1c1c;
}

.news-detail-wrap {
    /* Frame 427322761 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.625rem;

    width: 100%;
    height: auto;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.news-detail-short {
    /* Eglo connect sử dụng công nghệ Bluetooth Mesh bảo mật vượt trội, hệ thống đèn thông minh tách biệt với hệ thống mạng Smarthome, chỉ kết nối trực tiếp vào hệ thống mạng thông qua 1 thiết bị Gateway duy nhất. Bên cạnh đó, các chế độ thiết lập an ninh, cảm biến bật/tắt khi phát hiện chuyển động nâng cao bao gồm thiết lập thời gian hoạt động sẽ giúp bạn bảo vệ an toàn tài sản và tránh các rủi ro xâm nhập vào ban đêm */

    width: 100%;

    /* Inter/regular/.875rem-content */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    /* or 157% */

    /* Color/text/dark */
    color: #1c1c1c;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.news-detail-img {
    /* image 63 */

    width: 100%;
    height: auto;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.news-detail-content {
    width: 100%;
    height: auto;
}

.news-detail-post-day {
    /* Ngày đăng: 10/05/2024 */

    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    /* Color/text/gray */
    color: #767676;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.post-day {
    /* Ngày đăng: 10/05/2024 */

    width: 12.3125rem;
    height: 1.1875rem;

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    /* Color/brand/primary */
    color: #05a9ad;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.news-relate {
    /* Frame 427322757 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 2.5rem;

    position: relative;
    width: 100%;
}

@media screen and (max-width: 48rem) {

    .news-hightlight,
    .news-hightlight-right {
        height: auto;
        width: 100%;
        gap: 1.25rem;
        flex-wrap: wrap;
    }

    .news-hightlight-right-item {
        width: 100%;
    }

    .news-hightlight,
    .news-hightlight-left {
        height: auto;
    }

    .news-hightlight-left,
    .news-hightlight-right-item {
        max-height: 26.125rem;
        height: 18rem;
        width: 100%;
    }

    .hightlight-small-img {
        max-height: 26.125rem;
        height: 18rem;
    }

    .news-hightlight-right {
        flex-direction: row;
        flex-wrap: wrap;

    }

    .home-parag-news {
        width: calc(100% - 2 * 1.25rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
    }

    .news-list-item {
        width: 100%;
        max-height: 26.125rem;
        height: 18rem;
    }

    .news-list {
        flex-direction: row;
        height: auto;
        width: 100%;
        gap: 1.25rem;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0rem;
        position: relative;
        flex: none;
       /*  order: 1; */
        flex-grow: 0;
    }

    .news-image {
        width: 100%;
        max-height: 100%;
        height: 100%;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
        z-index: 0;
        padding: 0;
    }

    .news-img {
        width: 100%;
        max-height: 100%;
        height: 100%;
    }

    .news-content {
        /* Frame 27 */

        /* Auto layout */
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: .625rem;
        gap: .375rem;

        position: absolute;
        min-height: 5.875rem;
        height: auto;
        left: 0rem;
        right: 0rem;
        bottom: 0rem;

        background: rgba(255, 255, 255, 0.7);
        -webkit-backdrop-filter: blur(0.125rem);
        backdrop-filter: blur(0.125rem);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: .375rem .375rem 0rem 0rem;

    }

    .news-title {
        /* title-news */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: .25rem 0rem;
        gap: .625rem;

        width: 100%;
        height: auto;
        /* height: 1.875rem; */


        /* Inside auto layout */
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 1;
        /* Sự nâng cấp cho đèn pha lê hoa mai danh tiếng */

        /* Inter/medium/1rem */
        font-family: 'Alegreya';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.375rem;
        /* identical to box height */

        /* Color/text/dark */
        color: #1C1C1C;


    }

    .news-text {
        /* Frame 28 */

        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 0rem;
        gap: .625rem;

        width: 100%;
        height: auto;


        /* Inside auto layout */
        flex: none;
        align-self: stretch;
        flex-grow: 0;
        /* Bạn có biết rằng bướm là biểu tượng của sự hồi sinh, sự thay đổi và sự vui vẻ không? */



        /* Inter/regular/.875rem */
        font-family: 'Alegreya';
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.1875rem;

        /* Color/text/dark */
        color: #1C1C1C;
        margin-bottom: auto;


    }
    .news-hightlight {
        margin-top: 1rem;
    }
    .news-detail-cover{
        margin-top: 1.25rem;
        padding-bottom: 0;
        padding-top: 0;
        gap: 1rem;
    }
    .container-xxl-column{
        gap: 1.5rem;
    }
    .news-detail-header{
        text-transform:uppercase;
    }
    .news-detail-wrap{
        gap: 1rem;
    }
}