.dangky-context {
    /* Frame 427322782 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 3.8125rem;

    position: relative;
    width: 31.25rem;
    height: 13.0625rem;
    margin-top: 7.125rem;
}
.dangky-header {
    /* Đăng ký hoặc đăng nhập vào tài khoản Eglo của bạn */

    width: 31.25rem;
    height: 3.625rem;

    /* Inter/medium/1.5rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    text-align: center;

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.dangky-text {
    /* Sử dụng tài khoản eglo để tạo và quản lý danh mục đèn không giới hạn, cộng tác và chia sẻ cùng người khác, tùy chỉnh tài liệu kỹ thuật và nhiều hoạt động khác. */

    width: 31.25rem;
    height: 5.625rem;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.875rem;
    /* or 188% */
    text-align: center;

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.dangky-form-cover {
    /* Rectangle 1 */
    /* background: url("/public/icons/eglo/image.png"), rgba(255, 255, 255, 0.1); */
    box-sizing: border-box;
    width: 55.625rem;
    height: 36.125rem;
    position: relative;
    margin-top: 3.5rem;

    /* eff-glass */
    box-shadow: 0.5625rem 0.625rem 1.1875rem rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.5625rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 1.875rem;
}
.dangky-form {
    /* Frame 427322750 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.375rem;

    position: relative;
    width: 41.4375rem;
    height: 28.8125rem;
    left: 7.8125rem;
    top: 4.375rem;
}

.submit {
    /* Frame 427322767 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 0.75rem;

    width: 41.4375rem;
    bottom: 0rem;
    height: 3.8125rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.banco-taikhoan {
    /* Bạn đã có tài khoản? Vui lòng chọn Đăng nhập! */

    width: 41.4375rem;
    height: 1.0625rem;
    justify-content: center;
    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.vuilong-dangnhap {
    /* Bạn đã có tài khoản? Vui lòng chọn Đăng nhập! */

    height: 1.0625rem;

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;

    /* Color/brand/primary */
    color: #05a9ad;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.image-hand {
    /* Group 9729 */

    position: absolute;
    width: 33.25rem;
    height: 21.6456rem;
    left: -11.25rem;
    bottom: 0rem;
    z-index: -1;
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
}
img.hand {
    width: 33.25rem;
    height: 21.6456rem;
    /*  transform: rotate(180deg); */
}
.image-top-right {
    /* Ellipse 5 */

    position: absolute;
    width: 31.75rem;
    height: 31.75rem;
    right: -8.3125rem;
    bottom: 26.5625rem;
    z-index: -1;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(5, 169, 173, 0.3) 0%,
        rgba(30, 29, 36, 0.12) 100%
    );
}

/**
Login form
*/
.login-form-cover {
    /* Rectangle 1 */

    box-sizing: border-box;

    position: relative;
    margin-top: 5.6875rem;
    margin-right: 5.6875rem;

    width: 46rem;
    height: 30rem;
    /* background: url("/public/icons/eglo/image.png"), rgba(255, 255, 255, 0.1); */
    /* eff-glass */
    box-shadow: 0.5625rem 0.625rem 1.1875rem rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.5625rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 1.875rem;
}
.login-form-header {
    /* Frame 427322750 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.375rem;

    width: 14.1875rem;
    height: 2.3125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.login-google {
    /* Frame 427322769 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.625rem;
    gap: 0.25rem;

    height: 2.3125rem;

    background: #ffffff;
    border: 0.0625rem solid #ededed;
    border-radius: 0.25rem;
    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;

    /* Color/bg-dark */
    color: #1e1d24;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.login-form {
    /* Frame 427322750 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.375rem;

    position: absolute;
    width: 36rem;
    height: 22.5rem;
    left: 7.125rem;
    top: 4.1875rem;
}
.btn-refresh {
    width: 3.75rem !important;
    height: 3.625rem;
}
.login-form-lg {
    /* Frame 427322770 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.375rem;

    width: 100%;
    height: 18.8125rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.changepass-form-lg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.375rem;

    width: 41.4375rem;
    height: auto;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.captcha-cover {
    /* Frame 427322817 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.25rem;

    width: 41.4375rem;
    height: 3.625rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.captcha-img {
    /* Frame 427322816 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0rem;

    width: 10.6875rem;
    height: 3.5625rem;

    background: #ffffff;
    border-radius: 0.625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.lienhe-cover-all {
    display: flex;
    position: relative;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
}
.lienhe-cover {
    /* Frame 427322782 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 3.8125rem;

    position: relative;
    width: 31.25rem;
    height: 13.0625rem;
    left: 0.0625rem;
    top: 1.875rem;
}
.lienhe-header {
    /* LIÊN HỆ VỚI CHÚNG TÔI */

    position: absolute;
    width: 12.5625rem;
    height: 1.1875rem;
    left: 0rem;
    top: 0rem;

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);
}
.lienhe-text {
    position: absolute;
    width: 29.8125rem;
    height: 6.875rem;
    left: 0.25rem;
    top: 3.6875rem;

    /* Inter/regular/.875rem-content */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    /* or 157% */
    text-align: justify;

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);
}
.hr-lienhe {
    /* Line 5 */

    position: absolute;
    width: 30.0625rem;
    height: 0rem;
    left: 0rem;
    top: 12.75rem;

    /* Color/brand/secondary */
    border: 0.0625rem solid #d2af58;
}
.diachi-lienhe {
    /* info-contact */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0rem;

    position: relative;
    width: 32rem;
    height: 2.4375rem;

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
textarea.input-text-form {
    height: 6.625rem;
}
.home-form-control.textarea-noidung {
    height: 8.25rem;
}
.diachi-lienhe-cover {
    position: absolute;
    width: 23.75rem;
    left: 0.25rem;
    top: 15.0625rem;
}
.diachi-lienhe-cover-relative {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.125rem;
    position: relative;
    width: 23.75rem;
}
.dienthoai-lienhe {
    /* Hà Nội: (+84) 09 7150 7222 */

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.lienhe-form-cover {
    /* Frame 427322804 */

    position: relative;
    width: 55.625rem;
    height: 43rem;
    right: 0rem;
    top: 1.875rem;
    /* background: url("/public/icons/eglo/image.png"), rgba(255, 255, 255, 0.1); */
    /* eff-glass */
    box-shadow: 0.5625rem 0.625rem 1.1875rem rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.5625rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 1.875rem;
}
.lienhe-form {
    /* Frame 427322805 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.375rem;

    position: relative;
    width: 41.4375rem;
    height: 36.625rem;
    margin-top: 3.1875rem;
    margin-left: 7.8125rem;
}

.image-top-right-coner {
    /* Ellipse 5 */

    position: absolute;
    width: 26.4375rem;
    height: 26.875rem;
    right: -11.25rem;
    top: -4.5rem;
    z-index: -1;
}
.lienhe-top-right {
    /* Ellipse 5 */

    width: 100%;
    height: 100%;
    left: 93.5625rem;
    bottom: 50.1875rem;

    background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(5, 169, 173, 0.3) 0%,
        rgba(30, 29, 36, 0.12) 100%
    );
}
.submit-lienhe {
    /* Frame 427322678 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.4688rem 0.5rem;
    gap: 0.25rem;
    /* Tìm kiếm */

    /* Inter/Regular/.875rem */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* color/white */
    color: var(--Color-text-dark, #1c1c1c);

    width: 5.625rem;
    height: 2rem;

    /* Color/brand/primary */
    background: #05a9ad;
    border-radius: 0.25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.paragraph-chinhsach-baomat {
    /* Frame 427322812 */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 100%;
    height: auto;

    /* margin-top: 4.5rem; */
}
.paragraph-inner {
    box-sizing: border-box;

    position: relative;
    height: 100%;
    width: 100%;
    /* background: url(/public/icons/eglo/image.png), rgba(255, 255, 255, 0.1); */
    background: rgba(255, 255, 255, 0.1);
    /* eff-glass */
    box-shadow: 0.5625rem 0.625rem 1.1875rem rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.5625rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 1.875rem;
}
.chinhsach-baomat {
    /* CHÚNG TÔI LÀ AI? eglo.vn là trang web chính thức dành cho sản phẩm đèn trang trí EGLO – Cộng hòa Áo, được TTD Group nhập khẩu và phân phối chính thức tại thị trường Việt Nam từ năm 1992. Dưới đây là tuyên bố về chính sách bảo mật sẽ được áp dụng khi quý khách sử dụng dịch vụ tại website của chúng tôi. Vui lòng tham khảo trước khi tiến hành cung cấp thông tin và đặt hàng. Eglo.vn không cung cấp dịch vụ thanh toán trực tuyến, chúng tôi chỉ chấp nhận hình thức thanh toán chuyển khoản vào tài khoản do chúng tôi cung cấp trên đơn hàng. Mọi vấn đề phát sinh trong trường hợp chuyển khoản sai hoặc thanh toán trực tiếp không có sự ủy quyền (xác nhận bằng văn bản, hóa đơn chứng từ) chúng tôi xin miễn trừ trách nhiệm! */

    position: relative;
    width: 94.0625rem;
    height: auto;
    margin: 2.0625rem;

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);
}
.chinhsach-baomat-title {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    text-transform: uppercase;
}

.chinhsach-baomat-content {
    /* Inter/medium/1rem */
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    /* or 157% */
}

.slide-chinhsach-baomat {
    height: 46.5625rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.slide-chinhsach-item {
    /* image 71 */

    box-sizing: border-box;
    cursor: hand;
    position: absolute;
    margin: auto 0;
    /* Color/white */
    border: 0.0625rem solid #ffffff;
    border-radius: 0.375rem;
}
.slide-chinhsach-item.layer0 {
    /* image 71 */
    /* image 74 */
    z-index: 1;
    width: 27.1875rem;
    height: 27.5625rem;
}
.slide-chinhsach-item.layer0.left {
    left: 0rem;
}
.slide-chinhsach-item.layer0.right {
    right: 0rem;
}
.slide-chinhsach-item.layer1 {
    /* image 71 */
    z-index: 2;
    width: 31.0625rem;
    height: 38rem;
}
.slide-chinhsach-item.layer1.left {
    left: 10.0625rem;
}
.slide-chinhsach-item.layer1.right {
    right: 10.0625rem;
}
.slide-chinhsach-item.active {
    /* image 71 */
    z-index: 3;

    width: 31.0625rem;
    height: 46.5625rem;
    left: 0;
    right: 0;

    margin: auto;
    /* Color/white */
    filter: drop-shadow(0rem 0rem 1.5rem #ffffff);
}
.image-bottom-right-cover {
    /* Ellipse 5 */
    z-index: -1;
    position: absolute;
    width: 35.8125rem;
    height: 62.3125rem;
    right: -11.25rem;
    bottom: -28.625rem;

    /*  background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(5, 169, 173, 0.3) 0%,
        rgba(30, 29, 36, 0.12) 100%
    ); */
}
.img-inside {
    width: 100%;
    height: 100%;
}
.image-top-left-cover {
    /* Ellipse 6 */
    z-index: -1;
    position: absolute;
    width: 28.75rem;
    height: 59.4375rem;
    left: -11.25rem;
    top: -12.75rem;

    /* background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(5, 169, 173, 0.3) 0%,
        rgba(30, 29, 36, 0.12) 100%
    ); */
}
.chinhsachbanhang-cover {
    /* Rectangle 1 */

    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: auto;

    /* background: url(/public/icons/eglo/image.png), rgba(255, 255, 255, 0.1); */
    background: rgba(255, 255, 255, 0.1);
    /* eff-glass */
    box-shadow: 0.5625rem 0.625rem 1.1875rem rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.5625rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 1.875rem;
}
.chinhsachhanhang-text {
    /* Frame 427322799 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.625rem;
    margin: 2rem;
    margin-right: auto;
    position: relative;
    width: 48.75rem;
    height: auto;

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    /* or 157% */

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);

    border-radius: 0.375rem;
}
.chinhsachbanhang-picture {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.3125rem;
    flex-wrap: wrap;
    width: 39.4375rem;
    margin: 2rem;
}
.image-picture-center {
    /* Ellipse 4 */

    position: absolute;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    width: 4.5625rem;
    height: 4.5625rem;
    margin: auto auto;
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0rem 0rem 0.25rem #ffffff;
    z-index: 1;
}
.img-picture-center {
    /* hugeicons:policy */
    width: 2.25rem;
    height: 2.25rem;
}

.image-chinhsachbanhang {
    width: 18rem;
    height: 17.875rem;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center;
}

.img-chinhsachbanhang {
    /* img */

    width: 100%;
    height: 100%;
    filter: drop-shadow(0rem 0rem 1.25rem rgba(255, 255, 255, 0.25));
}
