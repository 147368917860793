.fontTahoma {
    /* Inter/medium/1rem */
    font-family: "Tahoma";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    /* Color/white */
    color: var(--Color-text-dark, #1C1C1C);
}

.fontAlegreya {
    color: var(--Color-text-dark, #1C1C1C);
    /* Inter/regular/14px */
    font-family: Alegreya;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.form-check-input {
    background-color: #ffffff;
}

.container-body {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
}

.container-xxl-column {
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 2.5625rem;
    width: 100%;
    height: auto;
    padding: 0rem;
}

.container-xxl-row {
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 2.5625rem;
    width: 100%;
    min-height: 31.25rem;
    padding: 0rem;
}