.footer-layout {
    /* height: 24.125rem; */
    left: 0rem;
    bottom: 0rem;
    position: relative;
    margin-top: 4.5rem;

    /* Color/bg/primary-dark */
    /* background: $footerLayoutColor;
    box-shadow: 0rem -0.25rem 1.25rem rgba(255, 255, 255, 0.06);
 */
}

.footer-layout-background {
    /* Color/bg/primary-dark */
    background: var(--footerLayoutColor);
    box-shadow: 0rem -0.25rem 1.25rem rgba(255, 255, 255, 0.06);
}

.footer-menu-bar {
    /* Auto layout */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 3.875rem;
    /* min-width: 61.8125rem; */
    margin: 0 auto 0 0;
    height: 3.8125rem;
    width: 100%;
}

.footer-menu {
    /* Auto layout */
    /* Frame 49 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0rem;
    /* gap: 44.625rem; */

    position: relative;
    width: 100%;
    height: 4.375rem;
    left: 0rem;
    margin-top: 2.0625rem;
}

.footer-menu-logo {
    /* logo eglo */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    width: 11.4375rem;
    height: 3.8125rem;
}

.footer-menu-text {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0rem;
    gap: 3.125rem;

    min-width: 46.5rem;
    height: 2.4375rem;
}

.footer-menu-text-item {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 0rem;
    gap: 0.625rem;
    height: 2.4375rem;

    /* Inter/medium/1rem */
    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    /* identical to box height */

    text-decoration: none;

    color: var(--Color-text-dark, #1c1c1c);
}

.footer-menu-text-item:visited {
    text-decoration: none;
}

.footer-logo-ggl {
    margin-left: auto;
    width: 9.75rem;
    height: 4.375rem;
}

.footer-contact {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    /* gap: .8125rem;
 */
    /* position: absolute; */
    width: 60%;
    /* left: 0rem; */
    bottom: 6.8125rem;
}

.footer-contact p {
    padding: 0rem;
    position: relative;
    min-height: 1.0625rem;
    height: auto;

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-grow: 0;
}

.footer-contact p span {
    flex-grow: 1;
    min-width: 3rem;
}

.footer-contact p b {
    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    /* identical to box height */
    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);
}

.footer-icon {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    padding: 0rem;
    gap: 1rem;
    height: 1.875rem;
}

a.footer-icon-item > img {
    width: 1.875rem;
    height: 1.875rem;
}

.footer-copyright {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem 0rem 0.5rem;
    gap: 0.625rem;

    height: 1.8125rem;
    position: relative;
    /* top: 22.0625rem; */

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    color: var(--Color-text-dark, #1c1c1c);
}

.footer-hr {
    margin-top: 1.9375rem;
    width: auto;
    height: 0rem;
    /* top: 22.0625rem;
    position: absolute; */
    opacity: 0.3;
    position: relative;
    /* Color/brand/secondary */
    border: 0.0625rem solid #d2af58;
}

.footer-contact-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0rem;
    gap: 1.75rem;

    /* position: absolute; */
    width: 24.5rem;
    height: 10.5625rem;
    /* right: 0rem; */
    /* left: 73rem; */
    /* bottom: 4.25rem; */
}

.footer-contact-box-body {
    /* Frame 50 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 0.625rem;

    width: 24.5rem;
    height: 6.125rem;
}

.footer-contact-box-body-text {
    /* Nhập thông tin email của bạn để nhận những chính sách ưu đãi từ chúng tôi ngay hôm nay */

    width: 24.5rem;
    height: 2.75rem;
    margin-bottom: 0;

    /* Inter/regular/.875rem-content */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    /* or 157% */
    text-align: justify;

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.footer-contact-box-body-textbox {
    /* Form / Search */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5313rem 0.4375rem;
    gap: 0.4375rem;

    width: 24.5rem;
    height: 2.75rem;

    /* BACKGROUND/B */
    background: #ffffff;
    /* LINE / C */
    border: 0.0625rem solid #c1c7d0;
    border-radius: 0.3125rem;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.footer-contact-box-button {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.75rem 2rem;
    gap: 0.625rem;

    width: 5.3125rem;
    height: 2.6875rem;

    /* Color/brand/primary */
    background: #05a9ad;
    /* Color/brand/primary */
    border: 0.0625rem solid #05a9ad;
    border-radius: 0.25rem;

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/white */
    color: #ffffff;
}

.footer-group1 {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
    margin-top: 1.5rem;
    justify-content: space-between;
}

@media screen and (max-width: 48rem) {
    .footer-layout {
        width: 100%;
    }

    .footer-menu {
        position: relative;
        padding-bottom: 9.25rem;
    }

    .footer-group1 {
        gap: 1.5rem;
        flex-wrap: wrap;
        width: 100%;
    }

    .footer-contact,
    .footer-contact-box {
        width: 100%;
        align-items: flex-start;
    }

    .footer-menu-bar {
        position: relative;
    }

    .btn-text-add-to-cart {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .footer-contact-box {
        align-items: flex-start;
    }

    .footer-menu-text {
        position: absolute;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        row-gap: 0rem;
        top: 100%;
        min-width: auto;
        /* left: 1.25rem; */
    }

    a.footer-menu-text-item {
        flex: 0 0 43%;
        /* Each item takes up 50% of the width, making two columns */
        box-sizing: border-box;
        /* Ensures padding and border are included in width */
    }

    .footer-contact-box-body {
        width: 100%;
    }

    a.footer-menu-text-item,
    .footer-contact-box-button {
        justify-content: flex-start;
    }

    .footer-hr,
    .footer-copyright {
        width: 100%;
    }

    .footer-contact-box-body-textbox {
        width: 100%;
    }
}
