/* Card */
.container-xxl-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 4.5rem;
    margin-top: 4.5rem;
}

.home-product-new {
    /* Frame 24 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 1.625rem;
    /* margin-bottom: 4.8125rem; */
    /* width: 86.4375rem; */
    width: 100%;
    /*     height: 40.4375rem;
 */
    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
    z-index: 1;
}

.home-product-new-div {
    /* Frame 24 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 3.125rem;

    width: 100%;
    /* height: 36.375rem; */

    /* Inside auto layout */
    flex: none;
    z-index: 1;
}

.home-product-new-div-card {
    /* Frame 26 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 2.5rem;

    width: 100%;
    /* height: 36.375rem; */

    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
}

.home-product-new-div-card-row {
    /* Frame 23 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-content: space-between;
    align-items: flex-end;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0rem;
    row-gap: 2.5rem;
    column-gap: 6.4375rem;

    width: 100%;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.home-product-new-div-card-item {
    /* card-item */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    padding: 0rem 0rem 1.25rem;
    gap: 1.25rem;
    isolation: isolate;
    text-decoration: none;
    margin: 0 auto;
    width: 40rem;
    height: 14rem;

    background: #ffffff;
    box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.home-card {
    /* 607e65f0d70590.30338001_101_0001 1 */
    width: 12.0625rem;
    height: 12.75rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 0;
}

.heart-bounder {
    /* add-favorite */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.625rem;
    gap: 0.625rem;

    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    left: 0.5rem;
    top: 0.5rem;

    background: #f2f2f2;
    border-radius: 50%;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 2;
}

.heart-nofill {
    /* Outline / Like / Heart  */

    width: 1.5rem;
    height: 1.5rem;

    border-radius: 0.3125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.home-card-box {
    /* Frame 20 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.75rem 0rem 0rem;
    gap: 3.25rem;

    width: 26.6875rem;
    height: 11rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
    z-index: 1;
}

.home-card-box-text {
    /* Frame 18 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.375rem;

    width: 100%;
    height: 4.1875rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.home-card-title {
    /* name-product */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0rem;
    gap: 0.625rem;

    width: 100%;
    height: 2.4375rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    /* 97855 – ROCCAFORTE */

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;
}

.home-card-text-content {
    /* Frame 19 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.625rem;

    width: 100%;
    /* Inter/regular/.875rem-content */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    /* identical to box height, or 157% */

    /* Color/text/dark */
    color: #1c1c1c;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.home-card-btn-box {
    /* Frame 17 */

    /* Auto layout */
    position: absolute;
    bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0rem;
    gap: 1.875rem;

    width: 20rem;
    height: 2.4375rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.home-card-price {
    /* Frame 15 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 0rem;
    gap: 0.625rem;
    /* 2.975.000₫ */

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    /* Color/brand/primary */
    color: #05a9ad;
}

.btn-add-to-shopping-card {
    /* btn-add-cart */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    gap: 0.625rem;

    height: 2.25rem;
    cursor: pointer;
    /* Color/brand/primary */
    background: #05a9ad;
    border-radius: 0.25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.shopping-card {
    /* Outline / Shopping, Ecommerce / Cart 6 */

    width: 1.5rem;
    height: 1.5rem;

    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
}

.btn-text-add-to-cart {
    /* Thêm vào giỏ */
    height: 1.0625rem;

    /* Inter/Regular/.875rem */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    /* identical to box height */

    /* Color/white */
    color: #ffffff;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

/* End of card */
/* news */
.home-parag-news {
    /* Frame 36 */
    position: relative;
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 1.625rem;

    width: 100%;

    /* Inside auto layout */
    flex: none;
    z-index: 3;
}

.home-parag-news-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 2.75rem;

    width: 100%;
}

.home-parag-news-item-big {
    /* image 13 */

    max-width: 62.1875rem;
    height: 40.5625rem;
    position: relative;
    flex-direction: column;

    display: flex;
    flex-wrap: wrap;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
    z-index: 0;
}

.home-parag-news-small-group {
    /* Frame 33 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.5625rem;

    max-width: 32.5625rem;
    height: 40.5625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.home-parag-news-item {
    /* image 13 */

    width: 100%;
    height: 19.5rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
    z-index: 0;
}

.home-news-img {
    /* image 13 */

    width: 100%;
    height: 100%;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    z-index: 0;
}

.home-float-img {
    position: absolute;
    width: 29.0625rem;
    height: 18.5625rem;
    right: 0.5625rem;
    bottom: 36.625rem;

    /*  transform: rotate(180deg); */
}

.home-parag-news-content {
    /* Frame 427322784 */
    /* Frame 427322784 */

    position: absolute;
    width: 100%;
    height: 6.0625rem;
    left: 0rem;
    bottom: 0rem;

    background: rgba(255, 255, 255, 0.6);
    border-radius: 0.625rem;

    /* Inside auto layout */

    flex-grow: 0;
    z-index: 1;
}

p.home-parag-news-content-title {
    /* title-news */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.25rem 0rem;
    gap: 0.625rem;

    position: absolute;
    width: 100%;
    left: 0.8125rem;
    top: 0.5rem;
    /* Sự nâng cấp cho đèn pha lê hoa mai danh tiếng */

    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    /* Color/text/dark */
    color: #1c1c1c;

    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

p.home-parag-news-content-text {
    /* Frame 28 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.625rem;

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.0625rem;

    /* Color/text/dark */
    color: #1c1c1c;

    position: absolute;
    width: 100%;
    left: 0.8125rem;
    top: 2.8125rem;
}

/* end news */
/* Đèn nội thất */

.home-parag-2 {
    /* Frame 427322794 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    flex: none;
    flex-grow: 0;
    z-index: 3;

    gap: 1.625rem;
}

.home-parag-2-1 {
    /* Frame 34 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 1.625rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.home-dennoithat-row {
    /* Frame 31 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0rem;
    gap: 0.25rem;

    width: 100%;
    height: 29.8125rem;

    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
}

.home-dennoithat-card {
    /* Frame 30 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 1;
    padding: 0.625rem;
    gap: 0.625rem;

    margin: 0 0;
    max-width: 22.75rem;
    height: auto;

    background: #f6f6f6;

    /* Inside auto layout */
    /* flex: none;

    flex-grow: 0; */
}

img.dennoithat-card {
    /* image 18 */

    width: 100%;
    height: auto;
}

.home-why-boder {
    /* Frame 427322785 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 3.5rem;

    width: 100%;
    height: 19.1875rem;

    /* Inside auto layout */
    flex: none;

    align-self: stretch;
    flex-grow: 0;
}

.home-parag-why {
    /* Frame 427322795 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    /* gap: 10.5625rem; */

    height: 2.625rem;

    /* Inside auto layout */
    
    flex: 0 0 auto;
}

.home-why-bar {
    /* Line 6 */

    /* Line 6 */
    border: 0.0625rem solid;
    max-width: 44%;
    height: 0rem;
    flex-shrink: 1;
}

.home-why-before {
    /* Line 6 */

    /* Line 6 */

    
    border-image-source: linear-gradient(
        90deg,
        rgba(30, 29, 36, 0.8) 0%,
        #d2af58 73.5%
    );
}

.home-why {
    /* Frame 427322793 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem 0rem 0.375rem;
    gap: 0.625rem;

    margin: 0 auto;
    height: 2.625rem;

    /* Inside auto layout */

    flex-grow: 1;

    /* TẠI SAO LÀ EGLO? */

    font-family: var(--fontFamily);

    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    color: var(--Color-text-dark, #1c1c1c);
}

.home-why-after {
    /* Line 7 */

    /*    transform: matrix(-1, 0, 0, 1, 0, 0); */
    
    border-image-source: linear-gradient(
        90deg,
        rgba(30, 29, 36, 0.8) 0%,
        #d2af58 100%
    );

}

.home-why-after-rotate {
    transform: rotate(180deg);
    width: 40.3125rem;
    gap: 0rem;
    opacity: 0rem;

    margin: 0 auto;
    height: 0rem;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border: 0.0625rem solid;

    border-image-source: linear-gradient(
        90deg,
        rgba(30, 29, 36, 0.8) 0%,
        #d2af58 100%
    );
}

.home-parag-why-row {
    /* Frame 427322792 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0rem;
    /* gap: 9.4375rem; */

    width: 100%;
    height: 13rem;

    /* Inside auto layout */
    flex: none;

    align-self: stretch;
    flex-grow: 0;
}

.home-parag-why-item {
    /* Frame 427322788 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 2rem;

    max-width: 25.75rem;
    height: 13rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    flex-shrink: 1;
}

.home-why-img-1 {
    /* hugeicons:license-maintenance */

    width: 3.125rem;
    height: 3.125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.home-why-content {
    /* Frame 427322789 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.75rem;

    width: 100%;
    /*   height: 7.875rem; */

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.home-why-content-title {
    /* Chất lượng và đảm bảo */

    width: 100%;
    height: 1.1875rem;

    font-family: var(--fontFamily);
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-align: center;

    color: var(--Color-text-dark, #1c1c1c);
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;

    /* Chất lượng và đảm bảo */
}

.home-why-content-text {
    /* Chúng tôi đặt ra các tiêu chuẩn cao về chất lượng sản phẩm và dịch vụ của mình. Đó là lý do tại sao tất cả các bước phát triển và sản xuất, từ ý tưởng sản phẩm, giai đoạn thiết kế đến hoàn thiện sản phẩm, đều diễn ra với quan điểm này. */

    width: 100%;

    font-family: var(--fontFamily);
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.1875rem;
    text-align: center;

    color: var(--Color-text-dark, #1c1c1c);

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

/* End of Đèn nôi thất */
.home-video {
    /* Rectangle 6 */
    height: auto;
    justify-content: center;
    align-items: center;
    display: flex;

    width: 99vw;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 4;
}

video.video {
    width: 100%;
    height: auto;
    z-index: 1;
}

.home-eglo-vietnam-cover {
    height: 14.1875rem;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home-eglo-vietnam {
    position: relative;
    /* Frame 51 */
    /* margin-top: 4.5rem; */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 1.625rem;

    width: 56.0625rem;
    height: 9.875rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 5;
}

.home-eglo-content {
    /* Frame 37 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: 1.5rem;

    width: 100%;
    height: 5.625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.home-eglo-text {
    /* Từ năm 2002, GGL Group đã mang đèn trang trí thương hiệu EGLO của Cộng Hòa Áo về Việt Nam. */

    /* Inter/regular/.875rem-content */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.375rem;
    /* or 157% */
    text-align: center;

    /* Color/text/dark */
    color: var(--Color-text-dark, #1c1c1c);

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.home-lienhe-form {
    /* Frame 427322806 */
    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.625rem;

    width: 100%;
    height: 36.0625rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.home-form {
    /* Frame 427322804 */

    width: 55.625rem;
    height: 31.8125rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* Rectangle 1 */
}

.home-form-cover-all {
    /* Rectangle 1 */

    box-sizing: border-box;

    position: absolute;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;

    /* background: url(image.png), rgba(255, 255, 255, 0.1); */
    /* eff-glass */
    box-shadow: 0.5625rem 0.625rem 1.1875rem rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(1.5625rem);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 1.875rem;
}

.home-form-cover {
    /* Frame 427322805 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.375rem;

    position: absolute;
    width: 41.4375rem;
    height: 26.625rem;
    left: 7.8125rem;
    top: 3.1875rem;
}

.home-form-control {
    position: relative;
    width: 100%;
    height: 3.625rem;

    border-radius: 0.3125rem;

    /* Inside auto layout */
    flex-shrink: 1;
    align-self: stretch;
    flex-grow: 0;
}

.home-form-control-label {
    position: relative;
    width: 100%;
    height: 1.8125rem;

    border-radius: 0.3125rem;

    /* Inside auto layout */
    flex-shrink: 1;
    align-self: stretch;
    flex-grow: 0;
}

.textarea {
    height: 8.25rem;
}

.home-form-control-textarea {
    /* Form / TextArea */
    position: relative;
    width: 41.4375rem;
    height: 8.25rem;

    border-radius: 0.3125rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.home-form-label {
    /* Tiêu đề */

    position: absolute;
    width: 100%;
    height: 1.0625rem;
    left: 0rem;
    top: 0.0625rem;

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */
    color: var(--Color-text-dark, #1c1c1c);
    display: flex;
    align-items: center;

    /* Color/white */
}

.home-require {
    /* Tiêu đề */

    /* Inter/regular/.875rem */
    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* identical to box height */
    display: flex;
    align-items: center;

    /* Color/red */
    color: #ec0e00;
}

.input-text-form {
    /* Frame 16290 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.375rem 0.4375rem;
    gap: 0.4375rem;

    position: absolute;
    height: 2rem;
    left: 0%;
    right: 0%;
    top: 1.625rem;

    /* BACKGROUND/B */
    background: #ffffff;
    /* LINE / C */
    border: 0.0625rem solid #c1c7d0;
    border-radius: 0.3125rem;
}

.textarea-form {
    /* Frame 16290 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.4375rem;
    gap: 0.4375rem;

    position: absolute;
    left: 0rem;
    right: 0rem;
    top: 1.625rem;
    bottom: 0rem;

    /* BACKGROUND/B */
    background: #ffffff;
    /* LINE / C */
    border: 0.0625rem solid #c1c7d0;
    border-radius: 0.3125rem;
}

.container-img {
    position: absolute;
}

.image-bottom-left {
    /* Ellipse 5 */

    /* Ellipse 5 */
    background-position: top right;
    background-size: 200%;
    position: absolute;
    width: 49.125rem;
    height: 32.75rem;
    left: 0rem;
    bottom: 0rem;
    bottom: -4.5rem;
    background-size: cover;
    z-index: -1;
}

.image-rignt-bottom {
    /* Frame 427322815 */

    position: absolute;
    width: 29.0625rem;
    height: 18.5625rem;
    right: 1.375rem;
    bottom: 34.875rem;
    /* top: 1.375rem;

    transform: rotate(180deg); */
}

.image-rignt-bottom-small {
    /* Frame 427322811 */

    position: absolute;
    width: 24.75rem;
    height: 7.75rem;
    right: 1.4375rem;
    bottom: 48.125rem;
    /* top: -1.375rem; */

    /*  transform: rotate(180deg); */
}
