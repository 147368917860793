.header-layout {
    /* Rectangle 3 */

    position: relative;
    width: 100%;
    height: 36.25rem;
    border-radius: 1rem;
    background-size: cover;
    /* Ensures the image covers the whole area */
    background-position: center;
    /* Centers the image */
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;
    background: #ffffff;
    /* margin-top: 2.25rem; */
}

.header-menu {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    box-sizing: content-box;
}

.header-stick {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-top: 2.125rem;
    padding-bottom: 2.25rem;
    /* margin-top: 2.13rem; */
    min-height: 8.1875rem;
    transition: all 1s;
    background-color: var(--Color-white, #ffffff);
}

.header-sticky {
    /* display: block; */
    display: flex;
    position: fixed;
    top: 0;
    padding-top: 0rem;
    padding-bottom: 0rem;
    min-height: 3.8125rem;
    /* border-bottom: .0625rem solid var(--backgroundColorGray, #767676); */
    z-index: 1000;
    align-items: center;
    box-shadow: 0rem 0.25rem 0.625rem rgba(5, 169, 173, 0.12);
    /* background-color: var(--Color-white, #ffffff);
 */
    /* margin-top: 2.13rem; */

    /* position: absolute;

    left: 0rem;
    top: 0rem; */
}

@media screen and (max-width: 48rem) {
    .header-layout {
        margin-bottom: 12.125rem;
        height: 11.3125rem;
        width: auto;
    }

    .header-stick {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 18.0625rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        min-height: 3.0625rem;
        margin-bottom: 1.1875rem;
        padding-bottom: 0;
        padding-top: 0;
        box-shadow: 0rem 0.25rem 0.625rem rgba(5, 169, 173, 0.12);

        /* margin-top: 2.13rem; */
        /* min-height: 3.8125rem;
         */
        height: 3.0625rem;
        transition: all 1s;
    }

    .header-sticky {
        /* border-bottom: .0625rem solid var(--backgroundColorGray, #767676); */
        z-index: 1000;
        padding-bottom: 0;
        padding-top: 0;
    }
}

.menu-left {
    display: flex;
    align-items: center;
    gap: 4.125rem;
    flex: 1 0 0;
    height: 100%;
}

.menu_logo {
    display: flex;
    width: 11.4375rem;
    /* height: 3.8125rem; */
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}
.menu_logo > .img-fluid {
    height: 100%;
}
@media screen and (max-width: 48rem) {
    .menu_logo {
        /* max-width: 4.6875rem;
        max-height: 1.5625rem; */
    }

    .menu-left {
        gap: 0;
    }
}

.topnav {
    display: flex;
    align-items: flex-start;
    gap: 1.6875rem;
}

.header-frame-left-icon-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    gap: 1.25rem;
}

@media screen and (max-width: 48rem) {
    .header-frame-left-icon-frame {
        order: 2;
    }
}

.header-frame-left-icon {
    width: 1.5rem;
    height: 1.5rem;
}

.header-img {
    width: 97.5rem;
    height: 36.25rem;
    flex-shrink: 0;
    border-radius: 1rem;
    position: absolute;
    left: 0;
    top: 0;
}

.slider-box {
    /* Frame 8 */

    /* Frame 8 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 2.25rem;
    position: absolute;
    width: 38rem;
    min-height: 15rem;
    left: 9.3125rem;
    top: 6.6875rem;
}

.slider-box-title {
    color: var(--Color-text-dark, #1c1c1c);

    width: 100%;
    height: 2.75rem;

    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 2.75rem;
}

.slider-box-text {
    width: 36.9375rem;
    color: var(--Color-text-dark, #1c1c1c);

    /* 200% */

    /* Tiết kiệm đến 90% khi lựa chọn nâng cấp căn nhà của bạn bằng dải sản phẩm Outlet của thương hiệu đèn trang trí EGLO - Áo */

    width: 100%;
    height: 5rem;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 2rem;
    /* or 200% */

    /* Color/text/dark */
    color: var(--Color-text-dark, #1c1c1c);
}

.slider-box-btn {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    gap: 0.625rem;

    width: auto;
    height: 3.1875rem;

    /* Color/text/dark */
    border: 0.0625rem solid var(--Color-text-dark, #1c1c1c);
    border-radius: 0.25rem;
}

.slider-box-btn-text {
    /* Xem thêm */

    /* Inter/regular/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/text/dark */
    color: var(--Color-text-dark, #1c1c1c);
}

.slider-bar {
    width: auto;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.625rem;

    position: absolute;
    height: 0.25rem;
    left: 9.3125rem;
    top: 24.6875rem;
}

.slider-bar-item {
    /* rec-slide */

    height: 0.25rem;
    width: 1.25rem;

    /* transform: rotate(-90deg); */

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    background: var(--Color-text-dark, #1c1c1c);
}

@media screen and (max-width: 48rem) {
    .slider-box {
        height: 11.3125rem;
        left: 1.3125rem;
        top: 1.125rem;
        gap: 0rem;
        right: 1.3125rem;
        width: auto;
    }

    .slider-box-title {
        font-size: 1.25rem;
        line-height: normal;
        height: auto;
    }

    .slider-box-text {
        height: 2.4rem;
        width: auto;
        line-height: normal;
        margin-top: 0.31rem;
        margin-bottom: 0.29rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .slider-box-btn {
        height: 2.4375rem;
        padding: 1rem 1rem;
    }
}

.active {
    background: var(--backgroundColorGray, #767676);
}

.header-hr {
    height: 0rem;
    opacity: 0.3;
    width: 100%;
    border: 0.0625rem solid #d2af58;
    margin: 0;
}

.header-insuarent-cover {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1rem;

    position: absolute;
    width: 100%;
    height: 5.375rem;
    left: 0rem;
    bottom: 0rem;

    background: rgba(55, 55, 55, 0.21);
    border-radius: 0rem 0rem 1rem 1rem;
}

.header-insuarent {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    /* gap: 18rem; */

    height: 4.375rem;

    align-self: stretch;
    flex-grow: 0;

    width: 100%;
    position: absolute;
    left: 0rem;
    bottom: 0rem;
}

.header-insuarent-bar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    flex-grow: 1;
    padding: 0rem;
    /* gap: 10rem;

    width: 67.1875rem; */
    height: 3.875rem;
    width: 100%;
}

.header-insuarent-item {
    display: flex;
    /* item-card */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    width: auto;
    height: 3.875rem;

    padding: 0.625rem;

    gap: 0.375rem;
}

.insuarent-item-icon {
    width: 1.625rem;
    height: 1.625rem;
}

.insuarent-item-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.25rem;
}

.insuarent-item__title {
    color: var(--Color-white, #fff);
    height: 1.1875rem;
    /* Inter/regular/.875rem */
    font-family: Alegreya;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0rem;
}

.insuarent-item__text {
    color: var(--Color-white, #fff);
    height: 1.1875rem;
    /* Inter/regular/.875rem */
    font-family: Alegreya;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0rem;
}

.header-ggl-img {
    width: 9.75rem;
    height: 3.875rem;
    margin-left: auto;
}

svg.shield-check-svg {
    fill: #ffffff;
    /* This might not work for all SVGs, depends on the SVG structure */
}

@media screen and (max-width: 48rem) {
    .container-breadcrumbs {
        margin-top: 1rem;
    }
    .slider-bar {
        display: none;
    }
    .header-insuarent {
        flex-direction: row;

        height: 3.25rem;
        margin-top: 0.88rem;
    }

    svg.shield-check-svg {
        fill: #1c1c1c;
        /* This might not work for all SVGs, depends on the SVG structure */
    }

    .header-insuarent-cover {
        height: 3.25rem;
        align-items: center;
    }

    .header-insuarent-bar {
        flex-direction: column;
        top: 100%;
        position: absolute;
        align-items: flex-start;
    }

    .insuarent-item__title,
    .insuarent-item__text {
        color: var(--Color-text-dark, #1c1c1c);
    }

    .header-ggl-img {
        width: 5.25rem;
        height: 2.375rem;
        flex-shrink: 0;
    }
}
