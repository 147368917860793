p.news {
    /* Inter/regular/.875rem-content */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.375rem;
    /* or 157% */
    gap: 1.625rem;

    width: 95.5rem;
    color: #000000;
}

.news-background {
    /* Frame 427322766 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.875rem 1rem;
    gap: 2.3125rem;

    width: 100%;

    background: #ffffff;
    border-radius: .625rem;
}
.ck-main-container {
    font-family: "Alegreya";
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.ck-content {
    font-family: "Alegreya";
    line-height: 1.6;
    word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
    min-width: 18.75rem;
    max-width: 100%;
}
