.giohang-cover {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0px;
    width: 100%;
    margin-top: 41px;
    height: auto;
}

.giohang-table {
    position: relative;

    /* Frame 427322735 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    gap: 17px;

    position: relative;
    width: 100%;
    height: auto;
}

.giohang-table-header {
    /* Frame 427322717 */

    box-sizing: border-box;
    position: relative;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 0px;

    width: 100%;
    height: 37px;

    background: #ffffff;
    /* Color/brand/primary */
    border-width: 1px 0px;
    border-style: dashed;
    border-color: #05a9ad;

    /* Sản phẩm */

    /* Inter/regular/14px */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    /* color/text-dark */
    color: #001535;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.giohang-check-all {
    /* Frame 427322726 */
    display: flex;
    align-items: center;
    width: 20px;
    height: 100%;
}

input[type="checkbox"]:checked:after {
    content: "\2713";
    color: white;
}

input[type="checkbox"] {
    accent-color: #05a9ad;
}

.form-giohang-input {
    /* Frame 427322726 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 10px;
    /* Color/brand/primary */
    background: #05a9ad;
    width: 16px;
    height: 16px;
    accent-color: #05a9ad;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sanpham-header {
    /* Frame 427322715 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;

    /* width: 1018px; */
    height: 29px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.giohang-gia-header {
    /* Frame 427322716 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;

    width: 160px;
    height: 29px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-soluong-header {
    /* Frame 427322717 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;

    width: 100px;
    height: 29px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sotien-header {
    /* Frame 427322718 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;

    width: 160px;
    height: 29px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.yeuthich-themvaogiohang-header {
    /* Frame 427322718 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;

    width: 200px;
    height: 29px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-thaotac-header {
    /* Frame 427322719 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;

    width: 100px;
    height: 29px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-table-row {
    /* Frame 427322725 */
    position: relative;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 5px;

    width: 100%;
    height: 92px;

    border-bottom: .8px dashed #d9d9d9;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.giohang-group-row, .giohang-group-row1 {
    /* Frame 427322725 */
    position: relative;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 5px;

    width: auto;
    height: auto;

    /* Inside auto layout */
    flex: none;
    
    
}
.giohang-group-row {
    flex-grow: 1;
}

.giohang-check-cell {
    /* Frame 427322726 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 10px;

    width: 20px;
    height: 20px;

    /* Color/brand/primary */
    background: #05a9ad;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sanpham-cell {
    /* Frame 427322720 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 10px;

    /* width: 1010px; */
    height: 80px;
    margin-left: 10px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sanpham-img {
    /* Frame 427322736 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 6px 0px;
    gap: 10px;

    width: 92px;
    height: 80px;

    background: #ffffff;
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sanpham-text {
    /* Frame 427322721 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 0px 0px;
    gap: 10px;

    /* width: 908px; */
    height: 23px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;

    /* ĐÈN THẢ EGLO 94938 - GAETANO 1 */

    /* Inter/medium/16px */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* Color/white */
    color: var(--Color-text-dark, #1c1c1c);
}

.giohang-gia-cell {
    /* Frame 427322659 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;
    gap: 4px;

    width: 160px;
    height: 27px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* 519.000 */

    /* Inter/medium/16px */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    /* Color/brand/primary */
    color: #05a9ad;
}

.giohang-soluong-cell {
    /* Frame 427322723 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 10px;

    min-width: 100px;
    height: 29px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-tang-giam {
    /* Frame 427322663 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;

    min-width: 32px;
    height: 29px;

    /* color/line */
    border: 1px solid #efefef;
    border-radius: 0px 2px 2px 0px;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.giohang-thaotac-cell {
    /* Frame 427322724 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;
    gap: 10px;

    width: 100px;
    height: 25px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-xoa-btn {
    /* Frame 427322724 */

    width: 100px;
    height: 25px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    /* Inter/medium/14px */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    /* Color/white */
    color: var(--Color-white, #ffffff);
}

.giohang-summerize-cover {
    /* Frame 427322728 */
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    width: 100%;
    height: 67px;
    margin-top: 213px;

    background: #ffffff;
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.16);
}

.giohang-summerize-row {
    /* Frame 427322734 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 29px;

    position: relative;
    width: 100%;
    height: 32px;

    /* Sản phẩm */

    /* Inter/Regular/14px */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    /* color/text-dark */
    color: #001535;
}

.giohang-sum-left {
    /* Frame 427322733 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 6px;

    margin: 0 auto 0 0;
    /* width: 395px; */
    height: 29px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.form-check-label {
    /* Frame 427322715 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* Inside auto layout */
    flex: none;
}

.giohang-xoa {
    /* Frame 427322715 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
    gap: 10px;

    width: 57px;
    height: 29px;

    /* color/text-dark */
    /* border-left: 1px solid #001535;
 */
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-sum-right {
    /* Frame 427322737 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    margin: 0 0 0 auto;
    width: 649px;
    height: 32px;

    /* Inside auto layout */
    /* flex: none; */
    flex-grow: 1;
}

.giohang-uudai {
    /* Form / Text */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;

    margin: 0 auto;
    width: 294px;
    height: 32px;

    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-uudai-text {
    /* Nhập mã ưu đãi */

    height: 17px;
    /* Inter/Regular/14px */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* Color/text/dark */
    color: #1c1c1c;
    display: flex;
    align-items: center;

    /* Color/text/dark */
    color: #1c1c1c;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-uudai-input {
    /* Frame 16290 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 7px;
    gap: 7px;

    width: 174px;
    height: 32px;

    /* BACKGROUND/B */
    background: #ffffff;
    /* color/border-input */
    border: 1px solid #ced4da;
    border-radius: 5px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.giohang-tienthanhtoan {
    /* Frame 427322731 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    min-width: 207px;
    height: 29px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.giohang-tongtien {
    /* Frame 427322732 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 36px;

    margin: 0 auto;
    width: 334px;
    height: 32px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.giohang-tien-thanhtoan {
    /* Frame 427322721 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;
    gap: 4px;

    min-width: 75px;
    height: 27px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;

    /* Inter/Medium/16px */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Color/red */
    color: #ec0e00;
}

.giohang-thahnhtoan {
    /* Tìm kiếm */

    /* Inter/Regular/14px */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* color/white */
    color: var(--Color-white, #ffffff);

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* Tìm kiếm */

    /* Frame 427322678 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7.5008px 8px;
    gap: 4px;

    width: 91px;
    height: 32px;

    /* Color/brand/primary */
    background: #05a9ad;
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.yeuthich-btn {
    /* Tìm kiếm */

    /* Inter/Regular/14px */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* color/white */
    color: var(--Color-white, #ffffff);

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* Tìm kiếm */

    /* Frame 427322678 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7.5008px 8px;
    gap: 4px;

    height: 32px;

    /* Color/brand/primary */
    background: #05a9ad;
    border-radius: 4px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.yeuthich-themvaogiohang {
    /* Tìm kiếm */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 0px;
    gap: 4px;

    width: auto;
    height: 27px;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* 519.000 */

    /* Inter/medium/16px */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Color/brand/primary */
    color: #05a9ad;
}
.giohang-thanhtien-text{
    display: none;
}
@media screen and (max-width: 768px) {
    .giohang-group-row {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 8px;
        margin-bottom: 8px;
        margin-left: 48px;
    }
    .giohang-group-row1 {
        justify-content: flex-start;
        flex-direction: row;
        display: flex;
        align-items: flex-start;
        padding: 0px;
        gap: .375rem;
    }
    .giohang-table{
        
    }
    .yeuthich-themvaogiohang, .giohang-thaotac-cell{
        justify-content: flex-start;
    }
    .giohang-table-row{
        height: auto;
    }

    .group-header {
        display: none;
    }
    .giohang-thanhtien-text{
        display: flex;
    }
    .giohang-gia-cell {
        align-items: flex-start;
        justify-content: flex-start;
    }
    .giohang-summerize-row{
        flex-wrap: wrap;
        width: 100%;
        height: auto;
    }
    .giohang-summerize-row, .giohang-sum-right{
        gap: .3rem;
    }
    .giohang-sum-left{
        border-bottom: 1px solid #B5B5B5;
    }
    .giohang-sum-left, .giohang-sum-right, .giohang-uudai, .giohang-tongtien{
        width: 100%;
        flex-wrap: wrap;
        height: auto;

    }
    .giohang-tien-thanhtoan{
        justify-content: flex-start;
    }
    .giohang-xoa{
        margin-left: auto;
    }
    .giohang-xoa, .giohang-xoa-btn{
        /* margin-left: auto; */
        background: #FFFFFF;
        color: var(--Color-text-dark);
        padding: .375rem 1rem;
    }
    .giohang-summerize-cover{
        height: auto;
        margin-top: 1.0625rem;
    }
}