.gioithieu-cover {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0rem;
    width: 100%;
    height: auto;
    /*  margin-top: 4.5rem; */
}

.gioithieu-slide-cover {
    /* Frame 427322746 */

    /* Auto layout */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 2rem;
    isolation: isolate;

    width: 100%;
    height: auto;
}

.gioithieu-header {
    /* Frame 427322747 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.25rem;

    width: 100%;
    height: auto;


    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    z-index: 0;

}

.gioithieu-tab {
    /* Frame 427322781 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 1.25rem;

    height: 2.4375rem;


    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

}

.gioithieu-tab-item {
    /* tab-home */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 2rem;
    isolation: isolate;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* Inter/medium/1rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.1875rem;

    /* Color/white */
    color: var(--Color-text-dark, #1C1C1C);
}

.gioithieu-tab-item.active-gt {
    /* Color/brand/secondary */
    border: none;
    border-bottom: 0.125rem solid #d2af58;
}

.gioithieu-header-content {
    /* "Thiết kế hiện đại, đi kèm với chức năng cao, giá cả phải chăng" Đây luôn là phương châm của chúng tôi. Ở đỉnh cao, chúng tôi phát triển và sản xuất các sản phẩm phù hợp với nhu cầu của khách hàng. Hiện tại, chúng tôi đang nghiên cứu chuyên sâu về chủ đề Chiếu sáng thông minh và đưa ánh sáng và ánh sáng thành dạng được nối mạng kỹ thuật số và dễ điều khiển. Dù tương lai sẽ mang lại điều gì về ánh sáng, chúng tôi sẽ tiếp tục mang các giải pháp chiếu sáng hiện đại cho mọi lĩnh vực của cuộc sống từ Tyrol của Áo đến toàn thế giới. */

    width: 100%;
    height: auto;

    /* Inter/regular/.875rem-content */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.375rem;
    /* or 157% */
    text-align: center;

    /* Color/white */
    color: var(--Color-text-dark, #1C1C1C);


    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.gioithieu-slider {
    /* Rectangle 6 */

    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    z-index: 1;

}

.img-gioithieu-slide {
    width: 100%;
    height: 54.625rem;
    object-fit: cover;
}

.gioithieu-slide-bar {
    /* Frame 427322797 */

    /* Auto layout */
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0rem;
    gap: 1.5625rem;
    isolation: isolate;

    height: 7.5rem;
    width: auto;
    margin-top: 1.4375rem;

}

.gioithieu-slidebar-btn {
    height: 100%;
    width: 1.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
}

.img-gioithieu-slidebar-btn {
    height: 1.5rem;
    width: 1.5rem;
}

.gioithieu-slidebar-item {
    /* Rectangle 7 */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13.375rem;
    height: 7.5rem;

    border-radius: .375rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 1;

}

.video-play-btn {
    /* octicon:play-24 */

    position: absolute;
    width: 2.125rem;
    height: 2.125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 2;

}

.video-play-btn-big {
    /* octicon:play-24 */

    position: absolute;
    width: 6.25rem;
    height: 6.25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 2;

}

.gioithieu-slidebar-item video {
    width: 100%;
    display: block;
    object-fit: cover;
}

.img-gioithieu-slidebar-item {
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.gioithieu-timeline-cover {
    /* Frame 427322748 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem;
    margin-top: 5.9375rem;

    position: relative;
    width: 81.6875rem;
    height: auto;

}

.timeline-item-cover {
    /* timline-left */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0rem;
    gap: 1.875rem;

    position: relative;
    width: 56.9375rem;
    height: 13.75rem;
}

.image-timeline {
    /* Frame 427322739 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: .625rem;
    gap: .625rem;

    width: 13.75rem;
    height: 9.5625rem;

    border: .0625rem dashed #EBEBEB;
    border-radius: .25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

}

.timeline-item-cover:nth-child(even)>.image-timeline,
.timeline-item-cover:nth-child(odd)>.timeline-content {
    order: 0;
}

.timeline-item-cover:nth-child(odd)>.timeline-content {
    align-items: flex-end;
    text-align: right;
}

.timeline-item-cover:nth-child(even) {
    margin-left: auto;
}

.timeline-item-cover:nth-child(odd) {
    margin-right: auto;
}

.timeline-item-cover:nth-child(odd)>.image-timeline,
.timeline-item-cover:nth-child(even)>.timeline-content {
    order: 2;
}

.img-timeline {
    /* image 60 */

    width: 12.5rem;
    height: 8.375rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;

}

.timeline-bar {
    /* Frame 427322742 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    width: 1rem;
    height: 13.75rem;


    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    order: 1;

}

.img-timeline-bar {
    height: 100%;
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.timeline-content {
    /* Frame 427322741 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: .625rem;

    width: 38.4375rem;
    height: auto;

    /* Inter/regular/.875rem-content */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.375rem;
    /* or 157% */

    /* Color/white */
    color: var(--Color-text-dark, #1C1C1C);


    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

}

.timeline-time {
    /* year */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .375rem .625rem;
    gap: .625rem;

    width: 5.3125rem;
    height: 2.125rem;

    /* Color/brand/primary */
    background: #05A9AD;
    border-radius: .375rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    /* Inter/regular/.875rem-content */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.375rem;
    /* identical to box height, or 157% */

    /* Color/white */
    color: var(--Color-text-dark, #1C1C1C);
}

.timeline-text {
    /* Frame 427322744 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem 0rem .375rem;
    gap: .625rem;

    width: 38.4375rem;
    height: 5.875rem;
    /* Inter/regular/.875rem-content */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.375rem;
    /* or 157% */

    /* Color/white */
    color: var(--Color-text-dark, #1C1C1C);

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.gioithieu-more-btn {
    /* button */
    position: relative;
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .625rem 2rem;
    gap: .25rem;

    height: 2.75rem;

    /* Color/text/placeholder */
    border: .0625rem solid #B5B5B5;
    border-radius: .25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;


    /* Inter/regular/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.0625rem;
    /* identical to box height */

    /* Color/white */
    color: var(--Color-text-dark, #1C1C1C);
    margin-top: 1.5rem;
    cursor: pointer;

}

.fullscreen {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.fullscreen img {
    max-width: 95%;
    height: 95%;
    object-fit: cover;
}