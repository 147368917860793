.catalogue-row {
    /* Frame 16300 */

    /* Auto layout */
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0rem;
    gap: 2.375rem;

    width: 100%;
    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.catalogue-card-item {
    /* item-catelogue */
    position: relative;
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: .875rem;
    isolation: isolate;

    width: 17.6875rem;
    height: 26rem;

    background: #ffffff;
    border: .0625rem solid rgba(210, 175, 88, 0.5);
    border-radius: .375rem;

    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
}
.catalogue-card-img {
    /* item-product */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: .625rem 0rem 0rem;
    gap: .625rem;

    width: 15.0625rem;
    height: 21.4375rem;

    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
    z-index: 0;
}
.catalogue-img {
    /* image 30 */

    width: 15.0625rem;
    height: 20.8125rem;

    flex: none;

    flex-grow: 1;
}
.catalogue-heart-favourite {
    /* add-favorite */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: .625rem;
    gap: .625rem;

    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    left: .5313rem;
    top: .5rem;

    background: #f2f2f2;
    border-radius: 624.9375rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 2;
}
.catalogue-card-content {
    /* Frame 16298 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem .625rem .75rem;
    /* gap: .625rem; */

    width: 17.6875rem;
    height: 3.6875rem;


    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    z-index: 1;
}
.catalogue-card-title {
    /* WOHNRAUMLEUCHTEN 2023/24 */
    /* Frame 16298 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem;
    gap: .375rem;

    width: 14.375rem;
    height: 1.0625rem;

    /* Inter/medium/.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: .875rem;
    line-height: 1.0625rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.catalogue-card-download {
    /* Frame 16299 */
    text-decoration: none;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: .25rem;

    /* Inter/medium/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 700;
    font-size: .875rem;
    line-height: 1.0625rem;
    /* identical to box height */
    height: 1.25rem;
    /* Color/brand/primary */
    color: #05A9AD;


    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
.catalogue-download-icon {
    /* Outline / Arrows / Arrow Down  */

    width: 1.25rem;
    height: 1.25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}
@media screen and (max-width: 48rem) {
    .catalogue-row{
        justify-content: center;
    }
    .catalogue-card-item{
        width: var(--productCardWidth);
        height: calc(var(--productCardWidth) * 1.47);

    }
  
    .catalogue-card-img, .catalogue-img{
        width: calc(var(--productCardWidth) - 2 * .625rem);
        height: calc(var(--productCardWidth) * 1.15 - .625rem);
    }
    .catalogue-card-content{
        width: 100%;
        height: 3.6875rem;
    }
    .catalogue-card-title{
        width: 100%;
        text-align: center;
    }
}