.sticky {
    position: sticky;
    top: 0;
    z-index: 1000;
}

/*top menu START*/
/* Dropdown Button */
.dropbtn {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem;
    gap: 0.625rem;
    height: auto;
    border-radius: 0.25rem;
    text-decoration: none;
    color: var(--Color-text-dark, #1c1c1c);
    /* Inter/medium/14px */
    font-family: Alegreya;
    /* font-size: 0.875rem;
    font-style: normal;
    font-weight: 500; */
    line-height: normal;
    border-radius: 0.375rem;
}

.dropdown > .dropdown-menu-group > .menu-arrow-right {
    display: none;
}
@media screen and (max-width: 48rem) {
    .dropdown > .dropdown-menu-group > .menu-arrow-right {
        display: flex;
    }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    z-index: 1;

    /* display: flex; */

    flex-direction: column;
    width: 13.1875rem;
    padding: 1rem 0.625rem;
    align-items: flex-start;
    gap: 0.5625rem;
    position: absolute;

    /* Color/white */
    border-radius: 0.25rem;
    background: var(--Color-white, #fff);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

/* Links inside the dropdown */
.dropdown-content > .menu-level-0 {
    /* Auto layout */
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5625rem;
    width: 100%;
    gap: 0.5625rem;
}

.menu-level-0 > .menu-level-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    /* padding: 1rem 0.625rem; */
    width: 100%;
    /* gap: 0.625rem; */
    border-radius: 0.375rem;
}

.menu-level-0 > .menu-level-1:hover {
    background-color: rgba(185, 246, 248, 0.32);
}

.menu-level-1 > a {
    display: flex;
    width: 11rem;
    align-items: center;
    gap: 0.625rem;
    text-decoration: none;
    color: var(--Color-text-dark, #1c1c1c);
}

.menu-level-1-1 > a {
    text-decoration: none;
    color: var(--Color-text-dark, #1c1c1c);
}

.menu-level-1:hover > .menu-arrow-right > .my-svg {
    background-color: rgba(185, 246, 248, 0.32);
}

.my-svg {
    height: 100%;
}

.menu-level-1 > .menu-arrow-right {
    width: 1.5rem;
    height: 1.5rem;
    background: var(--Color-white, #fff);
}

.menu-level-1-1 > a:hover {
    background-color: rgba(185, 246, 248, 0.32);
}

.menu-level-1:hover > .menu-level-1-1 {
    display: flex;
}

.menu-level-1 > .menu-level-1-1 {
    /* Auto layout */
    display: none;
    position: absolute;
    /* Position submenu at the top */
    z-index: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 13.1875rem;
    gap: 0.5625rem;
    left: 100%;
    /* margin-left: 0.5rem; */
    top: 0;
    padding: 1rem 0.625rem;
    /* padding: 0.5rem 0.625rem; */
    min-height: 100%;
    /* Color/white */
    border-radius: 0.25rem;
    background: var(--Color-white, #fff);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.menu-level-1-1 a {
    /* Auto layout */
    text-decoration: none;
    width: 100%;
}

.menu-arrow-right {
    display: flex;
    align-items: center;
    border-radius: 0.375rem;
    /* background-color: rgba(185, 246, 248, 0.32); */
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    border-radius: 0.375rem;
    text-decoration: none;
    color: var(--Color-text-dark, #1c1c1c);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: flex;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover,
.dropbtn:hover {
    background: var(--Color-brand-primary, #05a9ad);
}

.menu-left a.icon {
    display: none;
}
.topnav {
    z-index: 1200;
}
/* When the screen is less than 768 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the topnav (.icon) */
@media screen and (max-width: 48rem) {
    .topnav div.dropdown,
    .topnav a.dropbtn,
    .topnav a.dropdown-menu-group {
        display: none;
    }

    .dropdown > .menu-arrow-right {
        display: flex;
    }

    .menu-left a.icon {
        float: right;
        display: flex;
        flex-direction: column;
        order: 3;
        margin-left: 0.75rem;
    }
    a.menu_logo {
        order: 1;
    }
    .menu-left {
        float: right;
        display: flex;

        position: relative;
    }

    .topnav {
        align-items: flex-start;
        gap: 0.5625rem;
    }
    .dropdown:hover,
    .dropbtn:hover,
    .dropdown-menu-group:hover {
        background-color: rgba(185, 246, 248, 0.32);
    }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 48rem) {
    .topnav.responsive {
        display: flex;
        width: 13.1875rem;
        padding: 1rem 0rem;
        align-items: flex-start;
        gap: 0.5625rem;
        position: absolute;
        top: 100%;
        right: 0;
        /* order: 3;
 */
        /* Color/white */
        border-radius: 0.25rem;
        background: var(--Color-white, #fff);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

        flex-direction: column;
    }
    .topnav.responsive .dropdown-content {
        right: 100%;
    }

    .topnav.responsive div.dropdown > .dropdown-menu-group {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .topnav.responsive div.dropdown,
    .topnav.responsive a {
        float: none;
        display: flex;
        align-items: flex-start;
        width: 11rem;
        justify-content: flex-start;
    }

    .header-stick {
        margin-top: 0rem;
    }
}
