.row-product {
    /* Trang danh sách sản phẩm */
    margin-top: 1.6875rem;
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 2.3125rem;
    padding: 0rem;
    min-height: 42.8125rem;
}

/* .row-product::before, .row-product::after {
    content: '';
    display: table;
}
.row-product::after {
    clear: both;
} */
.product-column-left {
    /* Frame 16292 */

    /* Auto layout */
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0rem;
    gap: 1.25rem;

    flex: 0 0 15.0625rem;
    width: 15.0625rem;
    left: 0rem;
    top: 0rem;
}

.product-all-list {
    /* box-category */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    width: 100%;

    background: #ffffff;
    /* eff-box-search */
    box-shadow: 0rem 0rem 1rem rgba(251, 251, 251, 0.1);
    border-radius: .375rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.product-box-title {
    /* Frame 54 */

    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: .75rem .625rem;
    gap: .625rem;

    width: 100%;
    /* Color/brand/primary */
    border-bottom: .0625rem solid #05a9ad;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    /* Tất cả danh mục */

    /* Inter/medium/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;
}

.product-list {
    /* Frame 53 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: .75rem 0rem 1.125rem;
    gap: .75rem;

    width: 100%;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.product-list-item {
    /* item-menu */
    /* Frame 55 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 0rem 0rem .625rem;
    gap: .375rem;

    flex: 100%;
    height: 1.25rem;
    text-decoration: none;
    cursor: pointer;

    /* Đè̀n Downlight */

    /* Inter/regular/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;
}

.product-list-active {
    color: var(--colorBlue);
}

.product-list-see-more {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 0rem 0rem .625rem;
    gap: .375rem;

    flex: 100%;
    height: 1.25rem;

    /* Inter/medium/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/brand/primary */
    color: #05a9ad;
}

.product-list-item-check {
    /* item-menu */
    /* Frame 55 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 0rem 0rem 0rem .625rem;
    gap: .375rem;
    flex: 100%;
    height: 1.25rem;

    /* Đè̀n Downlight */

    /* Inter/medium/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;
}

.form-check {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem 0rem 0rem .625rem;
    gap: .375rem;

    width: 45%;
    height: 1.25rem;


    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;

}

.form-check-input.my-form-check-input {
    margin-left: 0rem;
}

.arrow-right {
    width: 1.5rem;
    height: 1.5rem;
}

.input-range {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .4063rem .4375rem;
    gap: .5rem;

    width: 5rem;
    height: 2rem;

    /* BACKGROUND/B */
    background: #ffffff;
    /* LINE / C */
    border: .0625rem solid #c1c7d0;
    border-radius: .3125rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.product-column-right {
    /* Frame 16301 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    padding: 0rem;
    gap: 2.25rem;

    position: relative;
    flex: 1 1 100%;
    top: 0rem;
}

.product-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 0rem;
    row-gap: 2.375rem;
    column-gap: 3.0625rem;

    position: relative;
    flex: 1 1 100%;
}

.product-card-item {
    /* Auto layout */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: .875rem;
    isolation: isolate;
    text-decoration: none !important;
    width: 13.5625rem;
    height: 18.1875rem;

    background: #ffffff;
    border: .0625rem solid rgba(210, 175, 88, 0.5);
    border-radius: .375rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.product-card-item:last-child {
    margin-right: auto;
}

.product-card-img {
    /* item-product */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: .625rem;
    gap: .625rem;

    width: 13.5625rem;
    height: 13.5625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 0;
}

img.card-img {
    width: 12.1875rem;
    height: 12.1875rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.heart-favourite {
    /* add-favorite */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: .625rem;
    gap: .625rem;

    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    left: 10.3125rem;
    top: .5rem;

    background: #F2F2F2;
    border-radius: 624.9375rem;
    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 2;
}

img.heard-card {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: .3125rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

img.heard-card.fill {
    background-color: #fff;
    filter: invert(46%) sepia(30%) saturate(3255%) hue-rotate(162deg) brightness(95%) contrast(102%);
}

.product-discount-label {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;

    position: absolute;
    width: 3.25rem;
    height: 1.8125rem;
    left: -0.25rem;
    top: .625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    z-index: 4;
}

.product-discount-label-text {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .1875rem .625rem;
    gap: .625rem;

    width: 3.25rem;
    height: 1.4375rem;

    /* Color/brand/primary */
    background: #05a9ad;
    border-radius: .3125rem .0625rem .0625rem 0rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;

    /* Inter/medium/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/white */
    color: #ffffff;
}

.product-discount-tail {
    /* Rectangle 3 */

    width: .25rem;
    height: .375rem;

    /* Color/brand/primary */
    gap: 0rem;
    transform: rotate(0deg);
    padding: 0rem;
}

.product-card-content {
    /* Frame 16294 */

    position: absolute;
    justify-content: center;
    align-items: center;
    bottom: .3125rem;
    left: .3125rem;
    right: .3125rem;
    align-self: stretch;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-grow: 0;
    gap: .625rem;
    height: 4.125rem;
    padding: 0 .625rem .75rem;
    /* width: 13.5625rem; */
    z-index: 1;
}

a.product-card-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.product-card-title {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem;
    gap: .375rem;

    width: 100%;
    height: 1.0625rem;
    justify-content: flex-end;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
    /* 39002 – TONERIA */

    width: 6.5625rem;
    height: 1.1875rem;

    /* Inter/medium/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1C1C1C;

}

.product-card-price {
    /* Frame 16299 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: .625rem;

    width: 5.8125rem;
    height: 1.0625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    /* Inter/medium/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/brand/primary */
    color: #05a9ad;
}