.detail-content-xxl {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.detail-content {
    /* Frame 16302 */

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    min-height: 42.8125rem;
    margin-top: 2.4375rem;

    background: #ffffff;
    box-shadow: 0rem 0rem 1rem rgba(255, 255, 255, 0.6);
    border-radius: 0.625rem;
}

.detail-content-image {
    /* Rectangle 3 */
    display: flex;
    justify-content: center;
    position: absolute;
    width: 60%;
    height: 100%;
    left: 0rem;
    top: 0rem;

    background: #f3f3f3;
    border-radius: 0.625rem 0rem 0rem 0.625rem;
}

.detail-content-img {
    /* image 54 */

    display: flex;
    justify-content: center;
    align-items: center;
    top: 0.5rem;
    position: absolute;
    width: auto;
    height: 32.6875rem;
    /* eft: 50%;

    transform: translate(-50%); */
    /* Centers both horizontally and vertically */
    transition: transform 0.5s ease;
}

.slider-container {
    overflow: hidden;
    /* Ensures only one slide is visible at a time */
    width: 100%;
    flex: 0 0 100%;
    position: absolute;
    max-width: 31.75rem;
    height: auto;
    /* left: 50%; */
    bottom: 1.25rem;
}

.slider-show {
    box-sizing: initial;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 0.5rem;
    /* height: calc(100% - 2 * .625rem); */

    /* transform: translate(-50%); */

    /* Each slide takes up 100% of the screen width */
    width: auto;
    /* transform: translate3d(-22.2269375rem, 0rem, 0rem); */
    transition-duration: 0ms;
    list-style: none;
}

.img-back,
.img-next {
    width: 1.5rem;
    height: 1.5rem;
    /* Inside auto layout */
    flex: none;
    cursor: pointer;
    flex-grow: 0;
}

.slide-img {
    /* Auto layout */
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem;
    gap: 0.625rem;

    width: 6.5625rem;
    height: 6.1875rem;

    background: #ffffff;
    border: 0.0625rem solid #f1f1f1;
    border-radius: 0.625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    flex-shrink: 1;
}

.detail-content_img {
    width: auto;
    max-height: 6.1875rem;

    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
}

.detail-content-img > .img-fluid {
    height: 100%;
    max-width: 100%;
    width: auto;
}

.dtail-like-box {
    /* add-favorite */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.625rem;
    gap: 0.625rem;

    position: absolute;
    width: 2.75rem;
    height: 2.75rem;
    left: 1.6875rem;
    top: 1.25rem;

    /* Color/white */
    background: #ffffff;
    border-radius: 624.9375rem;
}

.detail-like-img {
    width: 1.5rem;
    height: 1.5rem;

    border-radius: 0.3125rem;
}

.detail-content-box {
    /* Frame 16307 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 3.875rem;

    position: absolute;
    width: 35%;
    height: auto;
    right: 1.3125rem;
    top: 2.625rem;
}

.detail-box {
    gap: 1.25rem;
    position: relative;
    height: auto;
    /* justify-content: space-between; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.detail-box-text {
    /* Frame 16306 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 2.625rem;

    width: 100%;
    height: auto;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 1;
}

.detail-box-text-header {
    /* Frame 427322796 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.25rem;

    width: 100%;
    height: 6.125rem;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.detail-box-text-header-title {
    /* Frame 16305 */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.0625rem;

    width: 100%;
    height: 3.0625rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    /* ĐÈN THẢ EGLO 94938 - GAETANO 1 */

    /* Inter/medium/1.5rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.8125rem;
    /* identical to box height */

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    /* Color/text/dark */
    color: #1c1c1c;
}

.detail-box-price {
    /* Frame 16309 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 0.75rem;

    margin: 0 auto;
    width: 100%;
    height: 1.8125rem;
    /* Giá: 3.246.000 ₫ */

    /* Inter/medium/1.5rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;

    /* Color/text/dark */
    color: #1c1c1c;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;

    /* Inside auto layout */
    flex: none;
    flex-grow: 1;
}

.detail-price-list {
    display: flex;
    /* Inter/medium/1.5rem */
    /* font-family: 'Tahoma'; */
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    align-items: center;

    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;
}

.detail-price_discount {
    /* Frame 16310 */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 0.625rem;
    isolation: isolate;

    /* font-family: Tahoma; */
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.8106rem;
    text-align: left;
    color: var(--colorBlue);
}

.detail-price_list {
    /* font-family: "Tahoma"; */
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-decoration: line-through;
    /* Inside auto layout */
    color: var(--colorBlue);
}

.btn-blue {
    /* btn-add-cart */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1.5rem;
    gap: 0.625rem;

    height: 2.75rem;

    /* Color/brand/primary */
    background: var(--colorBlue);
    border-radius: 0.25rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
    border: none;
    color: #ffffff;
}

.detail-description {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.25rem;

    width: 100%;
    height: auto;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 1;
}

.detail-color-item {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 1.25rem;

    width: 100%;
    flex-wrap: wrap;
    height: 1.75rem;

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

.color-item {
    box-sizing: border-box;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 62.4375rem;
    border-color: black;
    border: 0.0625rem solid;
}

.color-boder1 {
    align-items: center;
    border: 0.0625rem solid #05a9ad;
    border-radius: 62.4375rem;
}

.color-boder.active {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem;
    gap: 0.625rem;

    width: 1.75rem;
    height: 1.75rem;

    border: 0.0625rem solid #000000;
    border-radius: 62.4375rem;

    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
}

.color-boder {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    gap: 0.625rem;

    width: 1.75rem;
    height: 1.75rem;

    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
}

.detail-description-text {
    /* info-att */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.25rem;

    width: 100%;
    height: auto;

    /* Inside auto layout */
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}

.description-text {
    display: flex;
    /* Điện áp: */

    flex: 0 0 7.5rem;
    max-width: 25%;

    /* Inter/medium/0.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/text/dark */
    color: #1c1c1c;
}

.description-value {
    /* Inter/regular/0.875rem */
    display: flex;
    flex: 0 0 auto;
    max-width: 75%;
    /* Inter/regular/0.875rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    /* identical to box height */
    /* identical to box height */
    margin-right: auto;
    /* Color/text/dark */
    color: #1c1c1c;

    /* Inside auto layout */
    flex-grow: 1;
}

@media screen and (max-width: 48rem) {
    .detail-content {
        flex-direction: column;
    }

    .detail-content-image,
    .detail-content-box {
        width: 100%;
        left: auto;
        top: auto;
    }

    .detail-content-box {
        position: relative;
        right: auto;
        width: calc(100% - 2 * 1.25rem);
        margin: 1.25rem;
    }

    .detail-content-image {
        min-height: 34.875rem;
        position: relative;
    }

    .detail-content-img {
        height: 26.125rem;
    }

    .slide-img {
        /* Auto layout */
    }
    .detail-content_img {
        max-height: calc(100% - 2 * 0.625rem);
    }
    .slider-container {
        bottom: 0.5rem;
    }
}
