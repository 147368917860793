:root {
    --textColor: #ffffff;
    --fontFamily: "Alegreya";
    --footerLayoutColor: #FFFFFF;
    --colorBlue: #05a9ad;
    --Color-white: #fff;
    --Color-brand-primary: #05a9ad;
    --Color-text-dark: #1c1c1c;
    --headerLayoutColor: #23222a;
    --backgroundColor: #fff;
    --backgroundColorGray: #767676;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
ul{
    margin-bottom: 0rem;
}

body {
    background: #FBFBFB;
    color: var(--Color-text-dark);
    font-family: var(--fontFamily);
    overflow: auto;
    margin: 0;
    /* Center the body horizontally */
    box-sizing: border-box;
}

.btn-black {
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    gap: 0.625rem;

    background-color: transparent;
    height: 2.5625rem;

    /* Color/white */
    border: 0.0625rem solid #ffffff;
    border-radius: 0.25rem;
    color: #ffffff;

    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.btn-black-44 {
    /* button */

    box-sizing: border-box;
    background-color: transparent;
    /* Auto layout */
    height: 2.75rem;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .625rem 2rem;
    gap: .25rem;
    /* Color/text/placeholder */
    border: 0.0625rem solid #b5b5b5;
    border-radius: 0.25rem;

    width: auto;

    /* Inter/regular/.875rem */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 1.0625rem;
    /* identical to box height */

    /* Color/text/dark */
    color: var(--Color-text-dark, #1C1C1C);

    /* Inside auto layout */
    flex: none;
    flex-grow: 0;
}

a {
    text-decoration: none;
}

.title-center {
    /* tab-home */

    box-sizing: border-box;
    text-transform: uppercase;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem;
    gap: 0.625rem;

    height: 2.4375rem;

    /* tab-home */


    /* Color/brand/secondary */
    border-bottom: 0.125rem solid #d2af58;

    /* Inside auto layout */
    flex: none;

    flex-grow: 0;
    /* ĐÈN NỘI THẤT */


    /* Inter/medium/1rem */
    font-family: "Alegreya";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.375rem;
    color: var(--Color-text-dark, #1C1C1C);

}
.container-breadcrumbs{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2.375rem;

}

ul.main-breadcrumbs, li.breadcrumb-item {
    margin-bottom: 0rem;
}

.main-breadcrumbs {
    /* breadcrumb */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 0rem;
    gap: 0.375rem;
    height: 1.125rem;
    /* position: absolute;
    
    left: 0rem;
    top: 1.875rem; */
}

.breadcrumb-item {
    /* item */

    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 0.625rem;

    height: 1.0625rem;

    /* Inter/medium/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/text/placeholder */
    color: #B5B5B5;
}

.breadcrumb-item__sticky {
    position: absolute;
    /*  position: sticky; */
    top: 4.875rem;

    flex-wrap: wrap;
    z-index: 999;
    display: flex;
    align-items: flex-start;
    align-content: center;
}

.breadcrumb-item>a,
.breadcrumb-item>a:visited,
.breadcrumb-item>a:hover {
    text-decoration: none;
    color: #b5b5b5;
}

.breadcrumbs-last-child {
    /* item */


    /* Inter/medium/.875rem */
    font-family: 'Alegreya';
    font-style: normal;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.1875rem;
    /* identical to box height */

    /* Color/text/dark */
    color: var(--Color-text-dark, #1C1C1C);


}

#app {
    font-family: "Alegreya";
}